<template>
  <div>
    <a-page-header title="Конструктор форм карточки" />

    <a-row type="flex">
      <a-col flex="220px">
        <div class="card m-1">
          <div class="card-header">Элементы</div>
          <div class="card-body">
            <WidgetList />
          </div>
        </div>
      </a-col>
      <a-col flex="auto">
        <div class="card m-1">
          <div class="card-header">Форма</div>
          <div class="card-body">
            <widget-form />
          </div>
          <div class="card-footer">
            <a-space>
              <a-button 
                @click="json = JSON.stringify(widgetList, null, 2);showJson = true;"
              >
                Export JSON
              </a-button>
              <a-button
                @click="showPreview = true;"
              >
                Form preview
              </a-button>
            </a-space>
            <a-modal v-model:visible="showJson" title="JSON">
              <pre>{{ json }}</pre>
            </a-modal>
            <a-modal v-model:visible="showPreview" title="Preview">
              <FormGenerator 
                v-if="showPreview"
                :items="widgetList" 
              />
            </a-modal>
          </div>
        </div>
      </a-col>
      <a-col flex="220px">
        <div class="card m-1">
          <div class="card-header">Свойства</div>
          <div class="card-body">
            <widget-config v-if="selectedWidget != null" />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import WidgetList from "@/components/form-generation/designer/sp-form-designer-item-list.vue";
import WidgetForm from "@/components/form-generation/designer/sp-form-designer-item-form.vue";
import WidgetConfig from "@/components/form-generation/designer/sp-form-designer-item-config.vue";
import FormGenerator from "@/components/form-generation/sp-form-generator.vue";
import useSharedState from "@/components/form-generation/designer/js/shared-state";

export default {
  name: "FormDesignerDemo",
  components: {
    WidgetList,
    WidgetForm,
    WidgetConfig,
    FormGenerator,
  },
  data() {
    return {
      json: null,
      showJson: false,
      showPreview: false,
    };
  },
  setup() {
    const { widgetList, selectedWidget } = useSharedState();
    return {
      widgetList,
      selectedWidget,
    };
  },
};
</script>

<style>
</style>